<template>
    <el-footer class="bottom-nav">
      <el-row type="flex" justify="space-around" class="nav-row">
        <el-col class="nav-col">
          <div @click="navigateTo('/list')" class="nav-button" :class="{ active: isActive('/list') }">
            <el-icon><List /></el-icon>
            <span>账号列表</span>
          </div>
        </el-col>
        <el-col v-if="!isHide && jobConfig.mallEnable" class="nav-col">
          <div @click="navigateTo('/mall')" class="nav-button" :class="{ active: isActive('/mall') }">
            <el-icon><Shop /></el-icon>
            <span>在线商城</span>
          </div>
        </el-col>
        <!-- <el-col class="nav-col">
          <div @click="navigateTo('/promotion')" class="nav-button" :class="{ active: isActive('/promotion') }">
            <el-icon><Promotion /></el-icon>
            <span>我的推广</span>
          </div>
        </el-col> -->
        <el-col class="nav-col">
          <div @click="navigateTo('/me')" class="nav-button" :class="{ active: isActive('/me') }">
            <el-icon><UserFilled /></el-icon>
            <span>个人中心</span>
          </div>
        </el-col>
      </el-row>
    </el-footer>
  </template>
  
  <script setup>
  import { useRouter, useRoute } from 'vue-router';
  import { List, Shop, UserFilled } from '@element-plus/icons-vue';
  import {isHide,isLoggedIn,jobConfig} from './store.js'
  const router = useRouter();
  const route = useRoute();
  
  const navigateTo = (path) => {
    router.push(path);
  };
  
  const isActive = (path) => {
    return route.path === path;
  };
  </script>
  
  <style scoped>
  .bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .nav-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    cursor: pointer;
    text-align: center;
    color: #333;
  }
  
  .nav-button .el-icon {
    margin-bottom: 2px;
    font-size: 24px;
  }
  
  .nav-button span {
    font-size: 12px;
    margin-top: 2px;
  }
  
  .nav-button.active {
    color: #409eff;
  }
  </style>
  